import React from 'react';
import { socket } from '../socket';

export function ConnectionManager({ isConnected }) {

    function connect() {
        socket.connect();
    }

    function disconnect() {
        socket.disconnect();
    }

    return (
        <div className='d-flex align-items-center justify-content-between py-2 px-3 bg-white'>
            <div>연결상태: {isConnected ? <span className='text-success'>접속중 <span style={{ color: '#999999', fontSize: '.9em' }}>, 입차대기 이벤트만 모니터링 합니다.</span></span> : <span className='text-danger'>미접속</span>}</div>
            <div>
                {!isConnected && <button className='btn btn-success btn-sm' onClick={ connect }>연결하기</button>}
                {isConnected && <button className='btn btn-danger btn-sm' onClick={ disconnect }>연결해제하기</button>}
            </div>
        </div>
    );
}