import React, { createContext, useContext, useEffect, useState } from 'react';
import { apifetch } from '../apifetch';


const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
	const [ me, setMe ] = useState(null);
    const [ cpId, setCpId ] = useState(null);

	const syncAuth = async () => {
		return apifetch('/auth/myinfo.php')
		.then(data => {
			setMe(data.loginInfo);
			setCpId(data.cp_id);
			return Promise.resolve(data.loginInfo);
		})
		.catch(error => {
			console.error(error);
			setMe(null);
		})
	}

	const logout = async () => {
        await apifetch('/auth/logout.php')
        
		localStorage.removeItem('accesstoken');

        await syncAuth();
	}

	return (
		<AuthContext.Provider
			value={{    
				me,
                cpId,

				syncAuth,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export {
	AuthContext,
	AuthContextProvider
};