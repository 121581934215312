import { useContext } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import ErrorText from "../../components/ErrorText";
import { apifetch } from "../../apifetch";
import { AuthContext } from "../../contexts/auth.context";
import { useNavigate } from "react-router-dom";


const ComplexList = [
    {
        cpId: 'HU4214',
        name: '일광 비스타동원 2차',
    },
    {
        cpId: 'ID6892',
        name: '사상중흥S클래스',
    },
    {
        cpId: 'NG0166',
        name: '명지 삼정 그린코아더베스트',
    },
    {
        cpId: 'NQ1466',
        name: '율현마을동원로얄듀크1차',
    },
]

function LoginPage() {
    const { syncAuth } = useContext(AuthContext);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            cpId: '',
            loginId: '',
            password: '',
        },
        validationSchema: yup.object().shape({
            cpId: yup.string().required('필수입력입니다.').length(6, '6자로 입력해주세요.'),
            loginId: yup.string().required('필수입력입니다.'),
            password: yup.string().required('필수입력입니다.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const body = {
                cp_id: values.cpId,
                login_id: values.loginId,
                password: values.password,
                passkey: 'apt4u+bokbuin',
            };

            const url = '/auth/login.php';

            apifetch(url, {
                method: 'POST',
                body: new URLSearchParams(body),
            })
            .then(data => {
                if (data.login_info.login_level != '5' && data.login_info.login_level != '3') {
                    alert('사용권한이 없는 로그인 정보입니다.');
                }
                else {
                    localStorage.setItem('accesstoken', data.jwt);
                    syncAuth()
                    .then(me => {
                        if (me) {
                            // TODO: toast -> 로그인했습니다.
                            navigate('/parking-present');
                        }
                    })   
                }
            })
        },
    });

    const { values, handleChange, handleBlur, setFieldValue, isSubmitting, handleSubmit, touched, errors, getFieldProps, resetForm } = formik;
    
    return (
        <div style={{ display: 'flex' }}>
            <img src="https://manager.apt4u.io/img/login_bg.jpg" style={{ height: '100vh', maxWidth: '50%', objectFit: 'cover' }}></img>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                <div>
                    <img  src="https://manager.apt4u.io/img/logo.png"></img>
                    <form className="mt-5" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="cpId" className="form-label">아파트단지</label>
                            <select className="form-select" {...getFieldProps('cpId')} id="cpId">
                                <option value="">단지를 선택하세요.</option>
                                {process.env.NODE_ENV === 'development' && <option value="LK3670">테스트 단지</option>}
                                {ComplexList.map(complex => <option key={complex.cpId} value={complex.cpId}>{complex.name}</option>)}
                            </select>
                            <ErrorText error={errors.cpId} touched={touched.cpId} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="loginId" className="form-label">아이디</label>
                            <input className="form-control" id="loginId" {...getFieldProps('loginId')} />
                            <ErrorText error={errors.loginId} touched={touched.loginId} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="userpassword" className="form-label">비밀번호</label>
                            <input type="password" className="form-control" id="userpassword" {...getFieldProps('password')} />
                            <ErrorText error={errors.password} touched={touched.password} />
                        </div>

                        <button className="btn btn-lg btn-primary" style={{ width: '100%'}}>로그인</button>
                    </form>
                </div>

                <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, backgroundColor: 'blud', textAlign: 'center' }}>
                    <p className="mb-0">부산 해운대구 센텀동로 99, 1220호&emsp;Tel : 1600-0159</p>
                    <p className="mb-0">대표 : 강신영&emsp;|&emsp;사업자등록번호 : 351-88-02791&emsp;|&emsp;Email : luvluz1103@gmail.com</p>
                    <p>Copyright 아파트4U, All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}
  

export default LoginPage;