import App from "./App";
import MainLayout from "./layout/MainLayout";
import Page404 from "./views/Page404";
import LoginPage from "./views/auth/LoginPage";
import ParkingPresentPage from "./views/present/ParkingPresentPage";
import TestUiPage from './views/test/TestUiPage';


  
const routes = [
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "login",
                element: <LoginPage />,
            },
            {
                path: '',
                element: <MainLayout />,
                children: [
                    {
                        path: "parking-present",
                        element: <ParkingPresentPage />,
                    },
                ]
            },
            {
                path: 'test-ui',
                element: <TestUiPage />
            },
            {
                index: true,
                element: <LoginPage />
            },
        ]
    },
    {
        path: "*",
        element: <Page404 />
    },
];

export default routes;