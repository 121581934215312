import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "./contexts/auth.context";

function App() {
    const { syncAuth } = useContext(AuthContext);

    useEffect(() => {
        syncAuth();
    }, []);

    return (
        <Outlet />
    );
}

export default App;