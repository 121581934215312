import { useContext, useEffect, useRef, useState } from "react";
import { apifetch } from "../../apifetch";


function ParkingLogsPanel({ visible }) {

    useEffect(() => {
        // apifetch('/car/black/get_blacklist.php')
        // .then(data => {
        //     setBlacklist(data.blacklist);
        // })
        // return () => { setBlacklist([]) };

    }, []);
    
    if (!visible) return null;

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', flexBasis: 0 }}>
            

            parking logs
        </div>
    );
}

export default ParkingLogsPanel;