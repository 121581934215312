import { ParkingsProvider } from "./ParkingsContext";
import ParkingPresent from "./ParkingPresent";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import PropTypes from 'prop-types';
import ParkingLogsPanel from "../parking-logs/ParkingLogsPanel";
import Footer from "../../components/Footer";
import RelsPanel from "../rels/RelsPanel";



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ backgroundColor: 'blue', height: '100%' }}
        >
            <Box sx={{ backgroundColor: 'green', height: '100%', display: 'flex', flexDirection: 'column' }}>
                {children}
            </Box>
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function ParkingPresentPage() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <ParkingsProvider>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'white', display: 'flex', flexDirection: 'column' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="실시간 입차현황" />
                    {/* <Tab label="주차 정보" /> */}
                    <Tab label="방문차량등록 내역" />
                </Tabs>
            </Box>

            <Box sx={{ flex: 1 }}>
                <ParkingPresent visible={value === 0} />

                {/* <ParkingLogsPanel visible={value === 1} /> */}

                <RelsPanel visible={value === 1} />
            </Box>

            <Footer />
        </ParkingsProvider>
    );
}
  

export default ParkingPresentPage;