import { Typography } from '@mui/material';
import React from 'react';
import colors from '../constants/colors';


const Nodata = ({children, style}) => {
	return (
		<Typography variant="body1" style={{ color: colors.textSecondary, ...style }}>{children || '데이터가 없습니다.'}</Typography>
	);
}

export default Nodata;