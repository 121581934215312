
export default function ErrorText({ error, touched }) {
    return (
        <>
            {error && touched
                ? <div className="text-danger">{error}</div> 
                : <div>&nbsp;</div>
            }
        </>
    );
}