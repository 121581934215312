
function Page404() {
    return (
        <div>
            404
        </div>
    );
}
  

export default Page404;