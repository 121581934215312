import { useContext, useEffect, useRef, useState } from "react";
import { apifetch } from "../../apifetch";
import './pagestyle.css'


function RelsPanel({ visible }) {
    const [ rels, setRels ] = useState([]);

    const [ trigger, setTrigger ] = useState();
    useEffect(() => {
        const query = {
            allowed_by: 'manager'
        };
        const qs = new URLSearchParams(query);
        
        apifetch('/park/search_car_rel.php?' + qs)
        .then(data => {
            setRels(data.rows);
        })
        return () => { setRels([]) };
    }, [ trigger ]);
    
    if (!visible) return null;

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', flexBasis: 0 }}>
            <div className="d-flex flex-row align-items-center justify-content-between py-2 px-4 bg-white">
                <div className="text-muted">최근 방문차등록 내역 50건을 조회합니다.</div>
                <button className="btn btn-sm btn-primary" onClick={() => { setTrigger(new Date().getTime()); }}>조회갱신</button>
            </div>

            <div className="table-fix-head">
                <table className="table table-light table-bordered" style={{ margin: 0 }}>
                    <thead>
                        <tr>
                            <th scope="col">일련번호</th>
                            <th scope="col">방문증 등록일시</th>
                            <th scope="col">방문허가일</th>
                            <th scope="col">차량번호</th>
                            <th scope="col">동,호</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rels.map((rel, idx) => <tr key={rel.seq}>
                            <th className="align-middle" scope="row">{rel.seq}</th>
                            <td className="align-middle">{rel.created_at}</td>
                            <td className="align-middle">{rel.reg_dt}</td>
                            <td className="align-middle">
                                {rel.car_num1 + ' ' + rel.car_num2}
                            </td>
                            <td className="align-middle">{rel.dong}동 {rel.ho}호</td>
                        </tr>)}
                    </tbody>
                </table>

                {/* {rels.length === 0 && <Nodata style={{ margin: '16px' }}></Nodata>} */}
            </div>
        </div>
    );
}

export default RelsPanel;