import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
import routes from './Routes';
import { AppContextProvider } from './contexts/app.context';
import { AuthContextProvider } from './contexts/auth.context';
import { ThemeProvider } from '@mui/material';
import theme from './theme';


const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <AppContextProvider>
      <AuthContextProvider>
        <RouterProvider router={router}>
        </RouterProvider>
      </AuthContextProvider>
    </AppContextProvider>
  </ThemeProvider>
);