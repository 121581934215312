function apiResult(res) {
    return new Promise((resolve, reject) => {
        const { success, data, message, code } = res;

        if (success) resolve(data); 
        else {
            alert(message || '요청 실패');
        }
    })
}

export async function apifetch(url, options) {
    console.log('api request:', url, options);

    // 사장님이 세션기간이 짧다고 해서 (앱 특성상 가만히 있는 시간이 많아서 세션이 종료됨) jwt 토큰을 사용
    const jwt = localStorage.getItem('accesstoken');
    const headers = jwt ? {
        Authorization: `bearer ${jwt}`,
    } : {}

    return fetch(process.env.REACT_APP_API_URL + url, {
        mode: 'cors',
        credentials: 'include',
        headers,
        ...options
    })
    .then(res => res.json())
    .catch(error => {
        console.log('json parsing error')   // 서버에러가 발생한 것으로 봐도 됨
        throw error;
        // return Promise.resolve({
        //     success: false,
        //     message: '서버응답실패',
        // })
    })  
    .then(apiResult)
    .then(data => { 
        console.log('api success: ', url, data);
        return Promise.resolve(data);
    });
}