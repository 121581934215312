import { Button } from '@mui/material';


function TestUiPage() {

    return (
        <div>
            <Button variant="contained">Hello world</Button>
        </div>
    );
}
  

export default TestUiPage;