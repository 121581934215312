import { useEffect, useState } from "react";
import { socket } from "../socket";
import { useEnteredCarNotOpen, useParkingsDispath } from "../views/present/ParkingsContext";


function useSocket(channelId) {
    const [isConnected, setIsConnected] = useState(socket.connected);

    const dispatch = useParkingsDispath();

    const { setEnteredCarNotOpen } = useEnteredCarNotOpen();

    useEffect(() => {
        if (!channelId) return;

        function onConnect() {
            setIsConnected(true);
            socket.emit('enter-danzi', channelId);
        }
    
        function onDisconnect() {
            setIsConnected(false);
        }

        function onEnteredCarNotOpen(data) {
            console.log('entered-car-not-open', data);
            dispatch({
                type: 'add',
                ...data,
            })
            setEnteredCarNotOpen(data);
        }
    
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('entered-car-not-open', onEnteredCarNotOpen);

        if (socket.connected) {
            socket.emit('enter-danzi', channelId);
        }
    
        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('entered-car-not-open', onEnteredCarNotOpen);
        };
    }, [ socket.connected, channelId ]);

    return ({
        isConnected,
    });
}
  

export default useSocket;