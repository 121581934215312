import React, { useContext } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/auth.context';


function MainLayout(props) {
    const { me, cpId, logout } = useContext(AuthContext);

    const naviage = useNavigate();

    const handleLogout = () => {
        logout()
        .then(() => {
            alert('로그아웃했습니다.');
            naviage('/login');
        })
    }

    return (
        <div style={{ backgroundColor: '#43425D' }}>
            <div className='container' style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                <nav className="navbar bg-light">
                    <div className="container-fluid">
                        <span className="navbar-brand mb-0 h1">주차관리</span>

                        <div className="d-flex align-items-center">
                            {cpId && <h6 className='ms-4 mb-0'>{cpId}</h6>}
                            {me && <h6 className='ms-4 mb-0'>{me.name || me.login_id}</h6>}
                            {me && <button className="btn btn-outline-primary ms-4" onClick={handleLogout}>로그아웃</button>}
                        </div>
                        
                    </div>
                </nav>

                {me ? <Outlet /> : <div style={{ padding: '40px' }}>
                    <div style={{ color: 'white' }}>로그인이 필요한 페이지 입니다.</div>
                    <Link to='/login' style={{ color: 'white' }}>로그인페이지로 이동</Link>
                </div>}
            </div>
        </div>
        
    );
}

export default MainLayout;