import { createContext, useContext, useReducer, useState } from "react";


const initialParkings = [
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2567",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
    {
        "cpId": "LK3670",
        "carNum1": "24조",
        "carNum2": "2568",
        "eventTime": "2024-05-24 12:10:00",
        "company": "amano",
        "refId": "111"
    },
    {
        "cpId": "LK3670",
        "carNum1": "69너",
        "carNum2": "1768",
        "eventTime": "2024-05-24 13:00:00",
        "company": "amano",
        "refId": "112"
    },
];

const ParkingsContext = createContext([]);
const ParkingsDispatchContext = createContext(null);
const EnteredCarNotOpenContext = createContext(null);

export function ParkingsProvider({ children }) {
    const [ parkings, dispatch ] = useReducer(ParkingsReducer, []);

    const [ enteredCarNotOpen, setEnteredCarNotOpen ] = useState();

    return (
        <ParkingsContext.Provider value={parkings}>
            <ParkingsDispatchContext.Provider value={dispatch}>
                <EnteredCarNotOpenContext.Provider value={{ enteredCarNotOpen, setEnteredCarNotOpen }}>
                    {children}
                </EnteredCarNotOpenContext.Provider>
            </ParkingsDispatchContext.Provider>
        </ParkingsContext.Provider>
    )
}

export function useParkings() {
    return useContext(ParkingsContext);
}

export function useParkingsDispath() {
    return useContext(ParkingsDispatchContext);
}

export function useEnteredCarNotOpen() {
    return useContext(EnteredCarNotOpenContext);
}


function ParkingsReducer(parkings, action) {
    switch (action.type) {
        case 'add': {
            const { cpId, carNum1, carNum2, eventTime, company, refId } = action;

            return parkings.concat([
                {
                    cpId, carNum1, carNum2, eventTime, company, refId
                }
            ]);
        }

        default: throw Error('Unknown actions: ', action.type);
    }
}