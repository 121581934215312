import { useContext, useEffect, useRef, useState } from "react";
import { ConnectionManager } from "../../components/ConnectionManager";
import useSocket from "../../hooks/useSocket";
import { useEnteredCarNotOpen, useParkings } from "./ParkingsContext";
import './pagestyle.css'
import EcnoDialog from "./EcnoDialog";
import { AuthContext } from '../../contexts/auth.context';
import Nodata from "../../components/Nodata";
import { apifetch } from "../../apifetch";


function ParkingPresent({ visible }) {
    const { cpId } = useContext(AuthContext);

    const { isConnected } = useSocket(cpId);

    const parkings = useParkings();
    const { enteredCarNotOpen } = useEnteredCarNotOpen();

    const lastElementRef = useRef();

    useEffect(() => {
        if (lastElementRef.current && visible) lastElementRef.current.scrollIntoView();
    }, [ parkings, visible ]);

    // entered car not open
    const [ ecnoData, setEcnoData ] = useState();
    const [ ecnoDialogOpen, setEcnoDialogOpen ] = useState(false);
    const [ ecnoDoneCars, setEcnoDoneCars ] = useState([]);
    useEffect(() => {
        if (enteredCarNotOpen && !ecnoDialogOpen) {
            setEcnoData(enteredCarNotOpen);
            setEcnoDialogOpen(true);
        }
    }, [ enteredCarNotOpen ]);
    const ecnoDone = (carNumer) => {
        setEcnoDoneCars(result => result.concat([carNumer]))
    }
    const isEcnoRegistered = (parking) => {
        return !!ecnoDoneCars.find(el => el === parking.carNum1 + parking.carNum2);
    }
    // end: entered car not open

    const handleRegisterCarVisit = (parking) => {
        setEcnoData(parking);
        setEcnoDialogOpen(true);
    }

    // --- 동, 호 ---
    const [ donghoList, setDongHoList ] = useState([]);
    useEffect(() => {
        const url = '/tenant/dongho/get_donghos.php';

        apifetch(url)
        .then((data) => {
            setDongHoList(data.donghos);
        })
    }, [ cpId ]);
    // --- end: 동, 호 ---

    // --- blacklist ---
    const [ blacklist, setBlacklist ] = useState([]);
    useEffect(() => {
        apifetch('/car/black/get_blacklist.php')
        .then(data => {
            setBlacklist(data.blacklist);
        })
        return () => { setBlacklist([]) };
    }, []);
    // --- end: blacklist ---

    if (!visible) return null;

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', flexBasis: 0 }}>
            <ConnectionManager isConnected={ isConnected } />

            <div className="table-fix-head">
                <table className="table table-light table-bordered" style={{ margin: 0 }}>
                    <thead>
                        <tr>
                            <th scope="col">일련번호</th>
                            <th scope="col">입차시각</th>
                            <th scope="col">이벤트</th>
                            <th scope="col">차량번호</th>
                            <th scope="col">플랫폼</th>
                            <th scope="col">관리</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parkings.map((parking, idx) => <tr key={idx} ref={(idx + 1 < parkings.length ? null : lastElementRef)}>
                            <th className="align-middle" scope="row">{parking.refId}</th>
                            <td className="align-middle">{parking.eventTime?.substring(11)}</td>
                            <td className="align-middle">{'입차대기'}</td>
                            <td className="align-middle">
                                {parking.carNum1 + ' ' + parking.carNum2}
                                {blacklist.indexOf(parking.carNum1 + parking.carNum2) > -1 && <span className="ms-2 text-danger fw-bolder fst-italic">{'[블랙리스트]'}</span>}
                            </td>
                            <th className="align-middle" scope="row">{'아마노'}</th>
                            <td className="align-middle">
                                <button className="btn btn-sm btn-outline-primary" disabled={isEcnoRegistered(parking)} onClick={() => { handleRegisterCarVisit(parking); }}>방문차량으로 등록</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table>

                {parkings.length === 0 && <Nodata style={{ margin: '16px' }}>현재 입차대기 차량을 기다리는 중입니다.</Nodata>}
            </div>

            <EcnoDialog
                open={ecnoDialogOpen}
                setOpen={setEcnoDialogOpen}
                parking={ecnoData}
                ecnoDone={ecnoDone}
                donghoList={donghoList}
                blacklist={blacklist}
            />

            
        </div>
    );
}

export default ParkingPresent;