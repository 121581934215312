import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { apifetch } from "../../apifetch";
import { Chip, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ErrorText from "../../components/ErrorText";


const Auto_Close_Timeout = 60;

const EcnoDialog = ({ open, setOpen, parking, ecnoDone, donghoList, blacklist }) => {

    // 자동으로 닫힘
    const [time, setTime] = useState(Auto_Close_Timeout);

    useEffect(() => {
        if (!open) return;

        setTime(Auto_Close_Timeout);
        let timer = setInterval(() => {
            if (!open) return;
            setTime((time) => {
                if (time === 0) {
                    clearInterval(timer);
                    return 0;
                } else return time - 1;
            });
        }, 1000);
        return () => { clearInterval(timer); }
    }, [ open ]);

    useEffect(() => {
        if (time === 0) setOpen(false);
    }, [ time ]);
    // 1분이내에 자동으로 닫힘

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            carNumber: parking ? (parking.carNum1 + parking.carNum2) : '',
            dong: '',
            ho: '',
        },
        validationSchema: yup.object().shape({
            carNumber: yup.string().required('필수입력입니다.').max(9, '9자 이내 입력해주세요.'),
            dong: yup.string().required('필수입력입니다.').max(5),
            ho: yup.string().required('필수입력입니다.').max(5),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const body = {
                amano_seq: parking.refId,
                dong: values.dong,
                ho: values.ho
            };

            const url = '/park/add_car_rel_by_manager.php';

            apifetch(url, {
                method: 'POST',
                body: new URLSearchParams(body),
            })
            .then(data => {
                alert('방문차량으로 등록했습니다.');
                setOpen(false);
                ecnoDone(values.carNumber);
            })
            .finally(() => { setSubmitting(false); resetForm(); })
        },
    });
    
    const { values, handleChange, handleBlur, setFieldValue, isSubmitting, handleSubmit, touched, errors, getFieldProps, resetForm } = formik;

    // --- 동, 호 ---
    const dongs = useMemo(() => {
        if (!donghoList) return [];
        
        const result = {};
        for (let row of donghoList) {
            result[row.dong] = 1;
        }
        return Object.keys(result);
    }, [ donghoList ]);

    const hos = useMemo(() => {
        if (!values.dong) return [];
        else return donghoList.filter(el => el.dong == values.dong).map(el => el.ho);
    }, [ values.dong ]);
    // --- end: 동, 호 ---

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => { 
                if (reason === 'backdropClick') return;
                setOpen(false);
            }}
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogTitle>입차대기 차량</DialogTitle>
            <DialogContent>
                <div className="my-5" style={{ textAlign: 'center' }}>
                    <h1>입차대기 차량: {parking?.carNum1 + " " + parking?.carNum2}</h1>
                    <h6 className="text-danger">{time}초 후에 자동으로 닫힙니다.</h6>
                </div>

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">방문차량 등록</h5>
                        <h6 className="text-muted">방문차량으로 등록하려면 동, 호 를 선택하고 등록 버튼을 클릭해주세요.</h6>
                        <h6 className="mb-4 text-muted">방문차량으로 등록하면 아파트포유앱을 사용하는 해당 동호수 입주민은 푸시알림을 받게되어, 방문사실을 알게 됩니다.</h6>
                        
                        <div className="mb-3 row">
                            <label htmlFor="carnumber" className="col-sm-2 col-form-label fs-5">차량번호</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control fs-5" id="carnumber" readOnly {...getFieldProps('carNumber')} />
                                {blacklist.indexOf(values.carNumber) > -1 && <span className="text-danger fw-bold">{'해당 차량은 무단 방문차량으로 신고되어 블랙리스트 명단에 포함된 차량입니다.'}</span>}
                                <ErrorText error={errors.carNumber} touched={touched.carNumber} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="dong" className="col-sm-2 col-form-label fs-5">동</label>
                            <div className="col-sm-10">
                                <select className="form-select fs-5" {...getFieldProps('dong')}>
                                    <option value="">동을 선택하세요.</option>
                                    {dongs.map(dong => <option key={dong} value={dong}>{dong}</option>)}
                                </select>
                                <ErrorText error={errors.dong} touched={touched.dong} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="ho" className="col-sm-2 col-form-label fs-5">호</label>
                            <div className="col-sm-10" style={{ minHeight: '150px' }}>
                                <input type="text" className="form-control fs-5" id="carnumber" {...getFieldProps('ho')} />
                                <ErrorText error={errors.ho} touched={touched.ho} />
                                <div className="mt-2">
                                    {hos.map(ho =>
                                        <Chip
                                            key={ho} 
                                            sx={{ mr: 2, mb: 1, width: '80px' }}
                                            label={ho} 
                                            color={values.ho === ho ? 'secondary' : 'default'}
                                            onClick={() => { setFieldValue('ho', ho )}} 
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            {process.env.NODE_ENV === 'development' && JSON.stringify(errors)}
            <DialogActions>
                <button type="button" className="btn btn-lg btn-secondary" onClick={() => { setOpen(false); }}>닫기</button>
                <button type="button" className="btn btn-lg btn-primary" onClick={handleSubmit}>방문차량으로 등록</button>
            </DialogActions>
        </Dialog>
    );
}

export default EcnoDialog;